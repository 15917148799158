import * as React from "react";

import Layout from "../../components/Layout";
import NewsRoll from "../../components/NewsRoll";
import heroImage from "../../img/jvictoriacollege-ca-hero-image.jpg";

export default class NewsIndexPage extends React.Component {
  render() {
    return (
      <Layout
        pageTitle="News"
        pageDescription="Collection of News related to J/Victoria College, Chulipuram and JVC OSA Canada."
        pageSlug="/news"
      >
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('${heroImage}')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
              backgroundColor: "#f40",
              color: "white",
              padding: "1rem",
            }}
          >
            Latest News
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <NewsRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
